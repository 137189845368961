console.log('>> Global loaded script.');
// import global dependencies
import '../../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { Fancybox } from '@fancyapps/ui';

/* ------------------ fancyapps ------------------- */
Fancybox.bind('[data-fancybox]', {
  // Your options go here
  infinite: false,
  Toolbar: {
    display: [
      { id: 'prev', position: 'center' },
      { id: 'counter', position: 'center' },
      { id: 'next', position: 'center' },
      'zoom',
      'slideshow',
      'fullscreen',
      'download',
      'thumbs',
      'close',
    ],
  },
});

/* ------------------ Back To Top ------------------- */
const scrollBtn = document.querySelector('#under-footer-back-to-top .btn');

scrollBtn.addEventListener('click', () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
});

/* ------------------ bootstrap-select + tabulator résultats ----------------- */
const selectPicker = document.querySelector('.selectpicker');
var url_string = window.location.href.toLowerCase();
var url = new URL(url_string);
var results = url.searchParams.get('results');

if (selectPicker) {
  selectPicker.addEventListener('change', function (e) {
    var optionSelected = e.target.value;
    if (optionSelected) {
      loadDataTable(optionSelected);
    }
  });
}
if (results) {
  selectPicker.value = results;
  loadDataTable(results);
}

function loadDataTable(icname) {
  var table = new Tabulator('#data-results', {
    height: '100%', // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
    layout: 'fitColumns', //fit columns to width of table (optional)
    pagination: 'local',
    paginationSize: 25,
    paginationSizeSelector: [10, 25, 50, true],
    paginationCounter: 'rows',
    locale: false,
    langs: {
      'fr-fr': {
        //French language definition
        pagination: {
          page_size: 'Afficher',
          page_title: 'Show Page',
          first: 'Premier',
          first_title: 'Première Page',
          last: 'Dernier',
          last_title: 'Dernière Page',
          prev: 'Précédent',
          prev_title: 'Page Précédente',
          next: 'Suivant',
          next_title: 'Page Suivante',
          all: 'Toute',
          counter: {
            showing: "Affichage de l'élément",
            of: 'sur',
            rows: 'éléments',
            pages: 'pages',
          },
        },
      },
    },
    ajaxURL: require('Datasets/' + icname + '.json'),
    ajaxContentType: 'application/json; charset=utf-8',
    ajaxContentType: 'json',
    initialSort: [
      //set the initial sort order of the data
      { column: 'nom', dir: 'asc' },
    ],
    columns: [
      { title: 'Nom', field: 'nom', headerFilter: 'input', headerFilterPlaceholder: 'Filtrer sur un nom' },
      { title: 'Prénom', field: 'prenom', headerFilter: 'input', headerFilterPlaceholder: 'Filtrer sur un nom' },
      { title: 'Sexe', field: 'sexe', headerFilter: 'input', headerFilterPlaceholder: 'Filtrer sur le sexe' },
      { title: 'Classement', field: 'classement', headerFilter: 'input', headerFilterPlaceholder: 'Filtrer sur le rang' },
      { title: 'Année', field: 'annee', headerFilter: 'input', headerFilterPlaceholder: 'Filtrer sur la catégorie' },
    ],
    ajaxResponse: function (url, params, response) {
      //url - the URL of the request
      //params - the parameters passed with the request
      //response - the JSON object returned in the body of the response.
      return response.data; //pass the data array into Tabulator
    },
  });
  table.on('dataLoaded', function (data) {
    table.setLocale('fr-fr');
  });
} // end function

/* ------------------ pour déplier le collapse depuis les news et agenda ----------------- */

if (location.hash != null && location.hash != '') {
  const target = document.querySelector(location.hash + '.collapse');
  target.classList.add('show');
}

/* ------------------ cloudinary gallery ----------------- */

let tag = document.querySelector('.tag');

if (tag && typeof tag.innerHTML !== null) {
  tag = tag.innerHTML;

  const cloudName = 'dxv2lgmfs'; // replace with your own cloud name
  const tags = { tag: tag };

  const myGallery = cloudinary.galleryWidget({
    container: '#portfolio-wrapper',
    cloudName: cloudName,
    mediaAssets: [tags],
    displayProps: { mode: 'expanded', columns: 4 }, // multi column display
    aspectRatio: '4:3', // if most assets are in landscape orientation
    // imageBreakpoint: 200,  // responsive resize images to closest step in 200px increments
    // carouselStyle: "indicators", // displays thumbnails by default
    // indicatorProps: { color: 'red' }, // only relevant if CarouselStyle is set to indicators
    // carouselLocation: "right",  // "left" by default
    imageBreakpoint: 150,
    viewportBreakpoints: [
      { breakpoint: 600, carouselStyle: 'thumbnails', carouselLocation: 'bottom' },
      { breakpoint: 300, carouselStyle: 'indicators', carouselLocation: 'bottom', navigation: 'always' },
    ],
    // borderColor: "red",  // color is transparent by default
    borderWidth: 5, // border width is 0 by default
    // transition: "fade",  // "slide" by default
    // zoom: true, // deactivate the zoom feature
    zoomProps: {
      type: 'popup',
      steps: 3,
      showTip: 'never',
      // stepLimit: true,
      // level: 1.3, // each step zooms in another 130%
    },
  });

  myGallery.render();
}
